@import url(font-awesome.min.css);
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600");

html {
	height: 100%;

}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
}

body {
	background-color: #3EECAC;
	background-image: linear-gradient(19deg, #11231c 0%, #8c5885 100%);
	background-repeat: no-repeat;


	-webkit-text-size-adjust: none;
}

body, input, select, textarea {
	font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
	font-size: 13pt;
	font-weight: 300;
	line-height: 1.65;
}

p {
	margin: 0 0 2em 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	line-height: 1.5;
	margin: 0 0 1em 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: inherit;
	text-decoration: none;
}

h1 {
	font-size: 2em;
}

@media screen and (max-width: 480px) {

	h1 {
		font-size: 1.5em;
	}

}

h2 {
	font-size: 1.75em;
}

@media screen and (max-width: 480px) {

	h2 {
		font-size: 1.4em;
	}
}

h3 {
	font-size: 1.35em;
}

h4 {
	font-size: 1.1em;
}

h5 {
	font-size: 0.9em;
}

h6 {
	font-size: 0.7em;
}
